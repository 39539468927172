<template>
  <div>
    <div class="page-container pb-0 mb-2">
      <filter-component
        @filterDistrict="handleFilter($event)"
      />
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="''"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDelete="false"
        :hideDowload="false"
        :hideAdd="false"
        @clickDelete="()=> {}"
        @clickDowloadSample="()=> {}"
        @clickExportExcel="()=> {}"
        @importFile="()=> {}"
        @clickAdd="()=> {}"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: false,
        }"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: số hiệu văn bản-->
          <span v-if="props.column.field === 'created'">
            {{ props.row.created| formatDateToDDMM }}
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'ActionFunction'">
            <span
              @click="showModalEdit(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xem nội dung'"
                icon="EyeIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="downloadFile(props.row.id, props.row.fileName)"
            >
              <feather-icon
                v-b-tooltip.hover.top="props.row.fileName ? 'Tải file':'Không có file'"
                icon="DownloadIcon"
                size="18"
                class="text-body"
              />
            </span>

          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.currentPage"
        @pageClick="pageChange"
      />

      <!-- thêm mới modal  -->
      <modal-create
        :id="modalIdCreate"
        :title="modalType==='Nội dung thông báo tổ chức'"
        :dataDetail="dataDetail"
        :type="modalType"
      />
    <!-- thêm mới modal  -->
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import FilterComponent from './components/FilterComponent.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreate from './components/ModalCreate.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import store from '@/views/management-statistical/store'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    Search,
    ButtonComponent,
    ConfirmModal,
    BButton,
    VBTooltip,
    ModalCreate,
    ButtonAllHeader,
    FilterComponent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      arrayExcel: [],
      formatFullName,
      columns: [
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'NGÀY THÔNG BÁO',
          field: 'created',
          sortable: false,
        },
        {
          label: 'NỘI DUNG',
          field: 'content',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      modalType: '',
      totalRecord: 0,
      dataDetail: {},
      idDetail: '',
      modalIdCreate: 'modalIdCreate',
      modalIdEdit: 'modalIdEdit',
      dataList: [],

    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // click show modal thêm vs xóa
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.idDetail = id
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_NOTIFICATION}${this.idDetail}`)
      this.dataDetail = data
      this.$bvModal.show(this.modalIdCreate)
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_NOTIFICATION, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    async downloadFile(id, fileName) {
      // const res = await store.downloadExportFile(fileName, ConstantsApi.DOWLOAD_FILE, { id })
      await axiosApiInstance({
        url: ConstantsApi.DOWLOAD_FILE, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      }).catch(e => {
        this.$root.$bvToast.toast('Không tìm thấy file', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    handleFilter(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
